

























































































































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import UserGroupModel from '@/modules/users/models/usergroup'
import CisUser from '@/modules/auth/models/cisuser'
import groupApi from '@/modules/users/api/usergroups'
import usersApi from '@/modules/users/api/users'
import Rules from '@/plugins/validations'
import esalesClient from '@/api/clients/esales'
import { INumericDictionary, IStringIndexed } from '@/modules/shared/types'
import PermissionsTree from './PermissionsTree.vue'
import Permission from '../models/permission'

@Component({
  components: {
    permissions: PermissionsTree
  }
})
export default class UserGroupForm extends Vue {
  @Prop({ default: false })
  private isDialog!: boolean

  @PropSync('group', {
    default: () => new UserGroupModel(),
    required: true
  })
  private usergroup!: UserGroupModel

  private allMembers: Array<CisUser> = []
  private members: Array<CisUser> = []
  private editMembers = false

  private allPermissions: Array<Permission> = []
  private enabledPermissions: Array<string> = []

  private errorMessage: string = ''
  private saving: boolean = false
  private valRules: Rules = Rules

  private memberHeaders: Array<any> = [
    { text: 'Name', align: 'left', sortable: true, value: 'fullName' },
    { text: 'Actions', align: 'center', sortable: false, filterable: false, value: 'action' }
  ]

  private editPerms = false
  private membersLoading: boolean = false
  private assignedSearch: string = ''
  private multiUnassign: Array<CisUser> = []

  private eligibleLoading: boolean = false
  private unassignedSearch: string = ''
  private multiAssign: Array<CisUser> = []

  public get readOnly () {
    if (!this.usergroup.id) {
      return !AuthState.user.isAllowed('USERS.GROUPS.CREATE')
    } else {
      return !AuthState.user.isAllowed('USERS.GROUPS.EDIT')
    }
  }

  public get mode () {
    return this.usergroup.id ? 'Edit' : 'Add'
  }

  public get eligibleMembers () {
    if (!this.members.length) {
      return this.allMembers
    }

    return this.allMembers.filter((i: CisUser) => {
      return !this.members.find((tm: CisUser) => {
        return tm.id === i.id
      })
    })
  }

  public removeMember (person: CisUser) {
    this.members.splice(this.members.findIndex((i: CisUser) => { return i.id === person.id }), 1)
  }

  public addMember (person: CisUser) {
    this.members.push(person)
  }

  public handleMultiAssign () {
    for (const person of this.multiAssign) {
      this.addMember(person)
    }
    this.multiUnassign = this.multiAssign
    this.multiAssign = []
  }

  public handleMultiUnassign () {
    for (const person of this.multiUnassign) {
      this.removeMember(person)
    }

    this.multiAssign = this.multiUnassign
    this.multiUnassign = []
  }

  public handlePermSelect (items: Array<string>) {
    // VTreeview seems to have a bug when the list is altered asynchronously outside the control
    // So in order to prevent the async load from affecting it on the first view, we need to ignore any updates
    // if edit isn't turned on
    if (this.editPerms) {
      Vue.set(this, 'enabledPermissions', items)
    }
  }

  public handleClose () : void {
    (this.$refs.form as Vue & { resetValidation: () => boolean }).resetValidation()
    this.$emit('dialog:close')
  }

  public async handleSubmit () : Promise<void> {
    if (this.readOnly || this.saving) {
      return
    }

    if (!(this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.saving = true
    this.errorMessage = ''

    const isNew: boolean = !(this.usergroup.id > 0)

    Vue.set(this.usergroup, 'permissions', {})
    this.enabledPermissions.forEach(n => {
      (this.usergroup.permissions as IStringIndexed)[n] = 1
    })

    try {
      const group = await groupApi.save(this.usergroup, this.members)
      if (isNew) {
        this.$emit('itemAdded', group)
      } else {
        this.$emit('itemUpdated', group)
      }

      if (this.members.find(m => m.id === AuthState.user.id)) {
        AuthState.RefreshLogin()
      }

      AppState.UpdateCisUsers(true)

      this.handleClose()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  @Watch('usergroup.id', { deep: true, immediate: true })
  private handleGroupChange () {
    Vue.set(this, 'enabledPermissions', Object.keys(this.usergroup.permissions))
    Vue.set(this, 'members', Object.values(AppState.cisUsers).filter((u: CisUser) => u.groupIds.indexOf(this.usergroup.id) !== -1))
  }

  public mounted () {
    Vue.set(this, 'allMembers', Object.values(AppState.cisUsers))

    usersApi.loadAllPermissions()
      .then(permissions => {
        this.allPermissions = permissions
        this.handleGroupChange()
      })
      .catch(err => (this.errorMessage = err))
  }
}
