var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Permissions ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.editEnabled=!_vm.editEnabled}}},[(!_vm.editEnabled)?_c('v-icon',[_vm._v("$vuetify.icons.edit")]):_c('v-icon',[_vm._v("$vuetify.icons.cancel")])],1)],1),_c('v-divider',{staticClass:"mb-4"}),(_vm.errorMessage)?_c('v-alert',{attrs:{"type":"error","text":""}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('v-treeview',{attrs:{"items":_vm.structure,"item-key":"id","item-disabled":"disabled","dense":"","selectable":_vm.editEnabled,"selected-color":"primary","hoverable":"","open-on-click":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [(!item.group)?_c('v-icon',{attrs:{"small":"","color":selected ? 'primary': ''}},[_vm._v(" $vuetify.icons.key ")]):_vm._e()]}},{key:"label",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return [_c('span',{class:(selected && !_vm.editEnabled) ? 'primary--text' : ''},[_vm._v(_vm._s(item.name))])]}},{key:"append",fn:function(ref){
var item = ref.item;
var leaf = ref.leaf;
var selected = ref.selected;
var indeterminate = ref.indeterminate;
var active = ref.active;
var open = ref.open;
return [_vm._t("append",null,null,{ item: item, leaf: leaf, selected: selected, indeterminate: indeterminate, active: active, open: open })]}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }