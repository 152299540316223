


























































import { Component, Vue } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import SalesPersonModel from '@/modules/auth/models/user'
import CisUser from '@/modules/auth/models/cisuser'
import SalesPerson from '@/modules/users/components/User.vue'
import userApi from '@/modules/users/api/users'
import { AppUserTypes } from '@/modules/shared/lists'
import { IStringDictionary, INumericDictionary } from '@/modules/shared/types'
import { AppState } from '@/stores/appStore'

@Component({
  components: {
    salesperson: SalesPerson
  }
})
export default class SalesPeople extends Vue {
  private salesPeople: INumericDictionary<CisUser> = {}
  private dialog: boolean = false
  private loading: boolean = false
  private salesSearch: string = ''

  private headers: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Type', align: 'left', sortable: true, filterable: false, value: 'userType' },
    { text: 'Company Name', align: 'left', sortable: true, value: 'companyName' },
    { text: 'First Name', align: 'left', sortable: true, value: 'firstName' },
    { text: 'Last Name', align: 'left', sortable: true, value: 'lastName' },
    { text: 'E-mail', align: 'left', sortable: false, value: 'emailAddress' },
    { text: 'Reports To', align: 'left', sortable: true, value: 'reportsTo' },
    { text: 'Active', align: 'center', sortable: false, value: 'active' },
    { text: 'Actions', align: 'center', sortable: false, filterable: false, value: 'action' }
  ]

  private userTypes = new AppUserTypes()

  private editedItem: CisUser = new CisUser()

  private errorMessage: string = ''

  private toggling: IStringDictionary<boolean> = {}

  private get canCreateUser () {
    return AuthState.user.isAllowed('USERS.CREATE')
  }

  private get canEditUser () {
    return AuthState.user.isAllowed('USERS.EDIT')
  }

  private get isDense () {
    return this.$vuetify.breakpoint.smAndDown
  }

  private get getHeaders () {
    let headers = this.headers

    if (this.isDense) {
      headers = headers.filter(i => { return i.text !== 'Actions' })
    }

    return headers
  }

  public reportsTo (userId: number) {
    return AppState.cisUserNames[userId]
  }

  private editItem (selectedItem: CisUser) {
    this.editedItem = selectedItem.clone()
    this.dialog = true
  }

  private close () {
    this.dialog = false
    this.editedItem = new CisUser()
  }

  private itemAdded (item: CisUser) {
    Vue.set(this.salesPeople, item.id!, item)
    AppState.AddSalesUser(new CisUser({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      emailAddress: item.emailAddress,
      companyName: item.companyName,
      reportsToId: item.reportsToId,
      userType: item.userType
    }))
  }

  private itemUpdated (item: CisUser) {
    Vue.set(this.salesPeople, item.id!, item)
    AppState.UpdateSalesUser(new CisUser({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      emailAddress: item.emailAddress,
      companyName: item.companyName,
      reportsToId: item.reportsToId,
      userType: item.userType
    }))
  }

  private async toggleActive (item: CisUser) {
    Vue.set(this.toggling, item.id!, true)
    try {
      const person = await userApi.save(item)
      this.itemUpdated(person)
    } catch (err) {
      this.errorMessage = err
    } finally {
      Vue.delete(this.toggling, item.id!)
    }
  }

  private async loadData () {
    this.errorMessage = ''
    this.loading = true
    try {
      const people = await userApi.loadAll()
      this.salesPeople = people
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.loading = false
    }
  }

  public created () {
    this.loadData()
  }
}
