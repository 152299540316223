
































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import PermissionModel from '@/modules/users/models/permission'
import { AppState } from '@/stores/appStore'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'

@Component({
  components: {}
})
export default class PermissionsTree extends Vue {
  @Prop({ required: true })
  private permissions!: Array<PermissionModel>

  @PropSync('chosen', { default: () => ([]) })
  private value!: Array<string>

  @PropSync('edit', { default: false })
  private editEnabled!: boolean

  private errorMessage: string = ''

  private get structure () {
    return PermissionModel.groupPermissions(this.permissions)
  }
}
