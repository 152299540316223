




















































import { Component, Vue } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import UserGroup from '@/modules/users/models/usergroup'
import UserGroupForm from '@/modules/users/components/Group.vue'
import groupApi from '@/modules/users/api/usergroups'
import { IStringDictionary } from '../../shared/types'

@Component({
  components: {
    'user-group': UserGroupForm
  }
})
export default class SalesPeople extends Vue {
  private groups: Array<UserGroup> = []
  private dialog: boolean = false
  private loading: boolean = false

  private headers: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Name', align: 'left', sortable: true, value: 'name' },
    { text: 'Active', align: 'center', sortable: false, value: 'active' },
    { text: 'Actions', align: 'center', sortable: false, filterable: false, value: 'action' }
  ]

  private editedItem: UserGroup = new UserGroup()
  private editedIndex: number = -1

  private errorMessage: string = ''

  private toggling: IStringDictionary<boolean> = {}

  private get isDense () {
    return this.$vuetify.breakpoint.smAndDown
  }

  private get canCreate () {
    return AuthState.user.isAllowed('USERS.GROUPS.CREATE')
  }

  private get canEdit () {
    return AuthState.user.isAllowed('USERS.GROUPS.EDIT')
  }

  private get getHeaders () {
    let headers = this.headers

    if (this.isDense) {
      headers = headers.filter(i => { return i.text !== 'Actions' })
    }

    return headers
  }

  private async editItem (selectedItem: UserGroup) {
    this.editedItem = selectedItem.clone()
    this.dialog = true
  }

  private close () {
    this.dialog = false

    this.editedItem = new UserGroup()
  }

  private itemAdded (item: UserGroup) {
    this.groups.push(item)
  }

  private itemUpdated (item: UserGroup) {
    const index = this.groups.findIndex(i => { return i.id === item.id })
    Vue.set(this.groups, index, item)
  }

  private async toggleActive (item: UserGroup) {
    try {
      Vue.set(this.toggling, item.id, true)
      const group = await groupApi.save(item)
      this.itemUpdated(group)
    } catch (err) {
      this.errorMessage = err.message
      item.active = !item.active
    } finally {
      Vue.delete(this.toggling, item.id)
    }
  }

  private async loadData () {
    this.errorMessage = ''
    this.loading = true
    try {
      this.groups = await groupApi.loadAll()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.loading = false
    }
  }

  public created () {
    this.loadData()
  }
}
