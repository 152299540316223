import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import UserGroup from '@/modules/users/models/usergroup'
import { AxiosResponse, Method } from 'axios'
import { IStringDictionary, IStringIndexed, INumericIndexed, INumericDictionary } from '@/modules/shared/types'
import AppUser from '@/modules/auth/models/user'
import CisUser from '@/modules/auth/models/cisuser'

interface IErrorResponse {
  errorMessage: string;
}

interface IUserGroupResponse {
  group: UserGroup;
}

interface IMultiUserGroupResponse {
  groups: Array<UserGroup>;
}

const baseUrl = 'user-groups'

export default class UserGroupsApi {
  public static async loadAll (filters?: IStringDictionary<string | Array<string|number>>): Promise<Array<UserGroup>> {
    try {
      const response: AxiosResponse = await esalesClient.get(baseUrl + filtersToQs(filters))

      if (response.status === 200 && response.data.groups) {
        const groups: Array<UserGroup> = []

        Object.entries(response.data.groups).forEach(([id, i]) => {
          groups.push(new UserGroup(<Partial<UserGroup>>i))
        })

        return groups
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async load (id: number): Promise<UserGroup> {
    try {
      const response: AxiosResponse = await esalesClient.get(baseUrl + '/' + id.toString())
      if (response.status === 200) {
        return new UserGroup(response.data.group)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async save (group: UserGroup, members?: Array<CisUser>): Promise<UserGroup> {
    try {
      let method = 'POST'
      if (group.id) {
        method = 'PUT'
      }

      const reqObj: IStringIndexed = {
        ...group
      }

      if (members) {
        reqObj.members = members.map(p => p.id)
      }

      const response: AxiosResponse = await esalesClient.request({
        url: baseUrl + (group.id ? '/' + group.id : ''),
        method: <Method> method,
        data: reqObj
      })

      if (response.status === 200 && response.data.group) {
        return new UserGroup((<IUserGroupResponse> response.data).group)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async delete (group: UserGroup): Promise<UserGroup> {
    try {
      const method = 'DELETE'

      const response: AxiosResponse = await esalesClient.request({
        url: baseUrl + '/' + group.id,
        method: <Method> method,
        data: group
      })

      if (response.status === 200 && response.data.group) {
        return new UserGroup((<IUserGroupResponse> response.data).group)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
