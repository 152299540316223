import { render, staticRenderFns } from "./UsersRoot.vue?vue&type=template&id=150a0073&scoped=true&web=true&"
import script from "./UsersRoot.vue?vue&type=script&lang=ts&"
export * from "./UsersRoot.vue?vue&type=script&lang=ts&"
import style0 from "./UsersRoot.vue?vue&type=style&index=0&id=150a0073&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "150a0073",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
