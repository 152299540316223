





























































































































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { StateAbbreviationsEnum, TimezonesEnum } from '@/modules/shared/enums'
import { IStringIndexed, IStringDictionary } from '@/modules/shared/types'
import { AppUserTypes } from '@/modules/shared/lists'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AuthState } from '@/modules/auth/store'
import CisUser from '@/modules/auth/models/cisuser'
import GroupModel from '@/modules/users/models/usergroup'
import usersApi from '@/modules/users/api/users'
import groupsApi from '@/modules/users/api/usergroups'
import Rules from '@/plugins/validations'
import { AppState } from '@/stores/appStore'
import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import PermissionsTree from './PermissionsTree.vue'
import Permission from '../models/permission'

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete,
    permissions: PermissionsTree
  }
})
export default class SalesPerson extends Vue {
  @PropSync('salesperson', {
    default: () => (new CisUser()),
    required: true
  })
  private person!: CisUser

  @Prop({ default: false })
  private isDialog!: boolean

  private timezones = mapObjectVuetifySelect(TimezonesEnum)

  private allGroups: Array<GroupModel> = []
  private groups: Array<GroupModel> = []
  private editGroups = false
  private groupsLoading = false
  private groupHeaders: Array<any> = [
    { text: 'Name', align: 'left', sortable: true, value: 'name' },
    { text: 'Actions', align: 'center', sortable: false, filterable: false, value: 'action' }
  ]

  private editPerms = false
  private multiAssign: Array<GroupModel> = []
  private multiUnassign: Array<GroupModel> = []

  private allPermissions: Array<Permission & { disabled?: boolean } > = []
  private personPerms: Array<string> = []
  private enabledPermissions: Array<string> = []

  private errorMessage: string = ''
  private saving: boolean = false
  private valRules: Rules = Rules

  public get readOnly () {
    if (this.canEditUser || this.canEditGroups || this.canEditPermissions) {
      return false
    }
    return true
  }

  public get canEditUser () {
    return AuthState.user.isAllowed('USERS.EDIT')
  }

  public get canEditGroups () {
    return AuthState.user.isAllowed('USERS.GROUPS.ASSIGN')
  }

  public get canSeePermissions () {
    return AuthState.user.isAllowed('USERS.PERMISSIONS.VIEW')
  }

  public get canEditPermissions () {
    return AuthState.user.isAllowed('USERS.PERMISSIONS.EDIT')
  }

  public get mode () {
    return this.person.id ? 'Edit' : 'Add'
  }

  public handleClose () : void {
    (this.$refs.salesPersonForm as Vue & { resetValidation: () => boolean }).resetValidation()

    this.editGroups = false
    this.editPerms = false
    Vue.set(this, 'groups', [])
    Vue.set(this, 'personPerms', [])
    this.errorMessage = ''

    this.$emit('dialog:close')
  }

  public async handleSubmit () : Promise<void> {
    if (this.readOnly || this.saving) {
      return
    }

    if (!(this.$refs.salesPersonForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.person.groupIds = this.groups.map(i => i.id)

    this.saving = true
    this.errorMessage = ''

    const isNew: boolean = !(this.person.id > 0)
    try {
      let person: CisUser = this.person
      if (this.canEditUser) {
        person = await usersApi.save(this.person)
        if (isNew) {
          this.$emit('itemAdded', person)
        } else {
          this.$emit('itemUpdated', person)
        }
      } else if (this.canEditGroups && !isNew) {
        person = await usersApi.saveGroups({ id: this.person.id, groupIds: this.person.groupIds })
        this.$emit('itemUpdated', person)
      }

      if (this.canEditPermissions) {
        const permissions: Array<string> = []
        this.enabledPermissions.forEach(p => {
          const perm = this.allPermissions.find(po => {
            return po.id === p
          })
          if (perm && !perm.disabled) {
            permissions.push(p)
          }
        })
        const perms = await usersApi.savePermissions(person.id, permissions)
      }

      this.handleClose()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  public get eligibleGroups () {
    if (!this.groups.length) {
      return this.allGroups
    }

    return this.allGroups.filter((i: GroupModel) => {
      return !this.groups.find((tm: GroupModel) => {
        return tm.id === i.id
      })
    })
  }

  public removeGroup (group: GroupModel) {
    this.groups.splice(this.groups.findIndex((i: GroupModel) => { return i.id === group.id }), 1)
  }

  public addGroup (group: GroupModel) {
    this.groups.push(group)
  }

  public getGroupName (item: IStringIndexed) {
    if (!item.disabled || item.children.length) {
      return ''
    }
    const groupMatches: Array<string> = []

    this.groups.forEach((g: GroupModel) => {
      Object.keys(g.permissions).forEach(n => {
        if (n === item.id) {
          groupMatches.push(g.name)
        }
      })
    })
    return '(' + groupMatches.join(', ') + ')'
  }

  public handleMultiAssign () {
    for (const group of this.multiAssign) {
      this.addGroup(group)
    }
    this.multiUnassign = this.multiAssign
    this.multiAssign = []
  }

  public handleMultiUnassign () {
    for (const group of this.multiUnassign) {
      this.removeGroup(group)
    }

    this.multiAssign = this.multiUnassign
    this.multiUnassign = []
  }

  public handlePermSelect (items: Array<string>) {
    // VTreeview seems to have a bug when the list is altered asynchronously outside the control
    // So in order to prevent the async load from affecting it on the first view, we need to ignore any updates
    // if edit isn't turned on
    if (this.editPerms) {
      Vue.set(this, 'enabledPermissions', items)
    }
  }

  @Watch('groups', { deep: true, immediate: true })
  private handleGroupChange () {
    Vue.set(this, 'enabledPermissions', ([] as Array<string>).concat(this.personPerms))
    Vue.set(this, 'allPermissions', this.allPermissions.map(p => { p.disabled = false; return p }))

    this.groups.forEach((g: GroupModel) => {
      Object.keys(g.permissions).forEach(n => {
        const permIdx = this.allPermissions.findIndex(p => {
          return p.id === n
        })

        if (permIdx !== -1) {
          Vue.set(this.allPermissions[permIdx], 'disabled', true)
          this.enabledPermissions.push(n)
        }
      })
    })
  }

  @Watch('personPerms', { deep: true, immediate: true })
  private handlePermsChange () {
    this.handleGroupChange()
  }

  @Watch('person.id', { deep: true, immediate: true })
  private async handlePersonIdChanged () {
    if (!this.person.id) {
      Vue.set(this, 'personPerms', [])
      return
    }
    try {
      Vue.set(this, 'personPerms', await usersApi.loadPermissions(this.person.id))
    } catch (err) {
      this.errorMessage = err
    }
  }

  @Watch('person.groupIds', { deep: true, immediate: true })
  private handlePersonGroupsChanged () {
    Vue.set(this, 'groups', [])
    this.person.groupIds.forEach(i => {
      const idx = this.allGroups.findIndex(g => g.id === i)
      if (idx !== -1) {
        this.groups.push(this.allGroups[idx])
      }
    })
  }

  public async created () {
    try {
      const permissions = await usersApi.loadAllPermissions()
      this.allPermissions = permissions
    } catch (err) {
      this.errorMessage = err
    }

    try {
      const groups = await groupsApi.loadAll()
      this.allGroups = groups
    } catch (err) {
      this.errorMessage = err
    }
    this.handlePersonGroupsChanged()
  }
}
