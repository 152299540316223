import { Base } from '@/modules/shared/models/base'
import { IStringIndexed, IStringDictionary } from '@/modules/shared/types'

export interface IUserGroup extends IStringIndexed {
  id: number;
  name: string;
  description: string;
  permissions: IStringDictionary<number>;
}

export default class UserGroup extends Base implements IUserGroup {
  public id = 0
  public name = ''
  public description = ''
  public permissions = {}

  constructor (props: Partial<IUserGroup> = {}) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
